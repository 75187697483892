<template>
	<div class="container">
		<policy-content :content="content"></policy-content>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import PolicyContent from '@/components/gadget/PolicyContent';

export default {
	components: {
		PolicyContent
	},
	data() {
		return {
			content: this.getContent()
		};
	},
	methods: {
		getContent() {
			const title = 'การรับประกันสินค้า/การเคลมสินค้า';
			const content = `
				<p>ขอต้อนรับสู่เว็บไซต์ yellowtire และ/หรือ yellowtire mobile application (&ldquo;เว็บไซต์&rdquo;) จัดทำและบริหารงานโดย บริษัท เยลโลไทร์ จำกัด (&ldquo;บริษัทฯ &rdquo; &ldquo;เรา&rdquo; หรือ &ldquo;ของเรา&rdquo;) กรุณาอ่านและทำความเข้าใจ <strong>ข้อตกลงและเงื่อนไข การให้บริการ / ข้อตกลงและเงื่อนไข การขาย / การรับประกันสินค้า/การเคลมสินค้า / นโยบายการคืนสินค้า การยกเลิกสินค้า และ การคืนเงิน / นโยบายความเป็นส่วนตัว / นโยบายคุกกี้</strong> โดยละเอียด ข้อกำหนดและเงื่อนไขเหล่านี้จะใช้บังคับกับการใช้งาน และการเข้าถึง และการใช้บริการแพลตฟอร์มของ yellowtire ด้วยการเข้าถึงแพลตฟอร์ม และ/หรือใช้บริการ ท่านตกลงที่จะผูกพันโดยข้อกำหนดเหล่านี้ หากท่านไม่ตกลงด้วยกับข้อกำหนดเหล่านี้ในการใช้บริการนี้ ห้ามเข้าใช้ และ/หรือใช้แพลตฟอร์มนี้ หรือใช้บริการ</p>

				<ol>
					<li>เงื่อนไขการรับประกันสินค้า บริษัทฯ ตกลงรับประกันสินค้าตามเงื่อนไขการรับประกันสินค้าของโรงงานผู้ผลิตสินค้า และ/หรือศูนย์บริการ และ/หรือผู้ขาย สินค้านั้นๆ โดยการรับประกันความเสียหาย และข้อบกพร่องของสินค้าที่เกิดจากการผลิตเท่านั้น และจะปฏิเสธไม่รับประกันสินค้าสำหรับกรณี ดังต่อไปนี้
					<ol>
						<li>ความเสียหายอันเกิดจากการใช้งาน</li>
						<li>ความสึกหรอที่เกิดจากการใช้งานตามปกติ</li>
						<li>ความเสียหายที่เกิดจากการติดตั้ง</li>
						<li>กรณีใช้รถในเชิงพาณิชย์ การใช้รถผิดประเภท การบรรทุกน้ำหนักเกินกำหนด หรือใช้เพื่อการแข่งขัน</li>
						<li>กรณีปรับแต่งเครื่องยนต์ เปลี่ยนเครื่องยนต์ ดัดแปลงรถ หรือติดตั้งแก๊สเชื้อเพลิง</li>
						<li>กรณีการถอดประกอบ แก้ไข ปรับแต่ง หรือเปลี่ยนสินค้าโดยบุคคลที่ไม่ได้รับการรับรองจากบริษัทฯ&nbsp;</li>
						<li>กรณีโอนถ่าย หรือถอดเปลี่ยนสินค้าไปติดตั้งรถคันอื่น</li>
						<li>กรณีขาดการดูแลรักษาตามเวลาและระยะเวลาที่กำหนดของสินค้านั้นๆ</li>
					</ol>
					</li>
					<li>การเคลมสินค้าทางบริษัทฯ จะประสานงานให้ส่งมอบสินค้าที่ต้องการเคลมให้ผู้ผลิตสินค้าเป็นผู้ตรวจสอบความเสียหายหรือข้อชำรุดบกพร่องที่เกิดจากการผลิตเท่านั้น การรับประกันสินค้าให้ถือผลการตรวจสอบของผู้ผลิตสินค้าเป็นสำคัญว่าจะรับประกันหรือไม่ โดยบริษัทฯ มิได้มีส่วนเกี่ยวข้องในกระบวนการตรวจสอบดังกล่าว</li>
					<li>ในกรณีที่ผู้ผลิตสินค้าตรวจสอบแล้วตกลงรับประกันสินค้า บริษัทฯ จะคืนเงินค่าสินค้าให้แก่ลูกค้าตามอัตราส่วนอายุการใช้งานที่เหลืออยู่ของสินค้าดังกล่าว ทั้งนี้ เป็นไปผลการตรวจสอบจากผู้ผลิตที่ได้แจ้งมา</li>
					<li>บริษัทฯ ไม่รับประกันและไม่รับผิดชอบความเสียหาย และความชำรุดบกพร่องที่เกิดกับอะไหล่ที่เกี่ยวเนื่อง และ/หรืออะไหล่ข้างเคียงกับสินค้า หรือความเสียหายต่อตัวรถ</li>
					<li>บริษัทฯ ไม่รับประกันและไม่รับผิดชอบในความเสียหายที่เกิดกับชีวิตและร่างกายของลูกค้า</li>
					<li>บริษัทฯ ขอสงวนสิทธิ์ในการดำเนินการเคลมสินค้าในเวลาไม่เกิน 8 สัปดาห์</li>
				</ol>
			`;

			return {
				title: title,
				detail: content
			};
		}
	},
	metaInfo() {
		const title = 'การรับประกันสินค้า/การเคลมสินค้า | YELLOWTiRE';
		const desc = 'การรับประกันสินค้า/การเคลมสินค้า YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
    }
}
</script>